import { usePage } from '@inertiajs/react';
import { useEffect } from 'react';
import { toast } from 'sonner';

export const useFlashNotifications = () => {
  const { flash } = usePage().props;
  window.toast = toast;
  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({ type, key, message, data }) => {
        const toastFn = typeof toast[type] === 'function' ? toast[type] : toast;

        toastFn(message, {
          id: key,
          duration: 'description' in data ? 8000 : 4000,
          ...data,
        });
      });
    }
  }, [JSON.stringify(flash)]);
};
